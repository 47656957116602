<!-- Finder particular de Compañías Centrales -->

<template>
  <div class="ciac_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera" v-if="Entorno.header.header">
          <base_Header 
            :componenteTipo="componenteTipo"
            :Entorno="Entorno.header"
            @onEvent="event_Header">
          </base_Header>
        </div>

        <!-- Filtro -->
        <div class="contenedor" style="width:1050px"> 
          <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
            <div class="filtro contenido">

              <v-row no-gutters>
                  <v-col md="6">
                    <v-text-field
                      v-bind="$input"
                      v-model="schema.ctrls.cia.value"
                      :label="schema.ctrls.cia.label"
                      @keypress.13="event_search('buscarCia')">                  
                    </v-text-field>
                  </v-col>

                  <v-col md="1">
                    <v-btn
                      v-bind="$cfg.btn.busca"
                      @click="event_search('buscarCia')">                  
                        <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col md="6">
                    <ctrlfinder finderName="atlas_F" :schema="schema.ctrls.atlas"> </ctrlfinder>
                  </v-col>
                </v-row>

            </div>
          </div>        

          <!-- Grid -->
          <base_Fgrid 
            :padre="stName" 
            :Entorno="Entorno" 
            dense
            @onEvent="event_Grid">

            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">         
              <div style="padding:10px 0 0 10px;">
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                </btracombi>
              </div>
            </template>

            <!-- Slot campo acciones del Grid -->
            <template v-slot:acciones="{ Sitem }">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </template>
          </base_Fgrid>
        </div>
      </v-sheet>

      <!-- Mto como ventana modal -->      
      <v-dialog
        v-model="modal"
        content-class="modal" persistent>
          <div class="centrado">             
            <component                 
                :is="component_M"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>  
    </div>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const ciac_M = () => plugs.groute("ciac_M.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, ctrlfinder, ciac_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,
        api:'ciac_F',
        stName:'stFciac',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,

        headers: {}
      };
    },

    methods: {
    
      // configuro entorno particular
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // entorno
        this.Entorno.header.titulo = "Compañías Centrales";        
        this.headers = {
          header: [
                { text: "Acc", value: "acciones", sortable: false, width: "10%", slot:true },
                { text: "Compañia", value: "name", width: "35%" },
                { text: "Sit", value: "situ", width: "10%" },
                { text: "Tipo", value: "tipo", width: "10%" },
                { text: "Atlas", value: "atlas", width: "35%" }                
              ]
        };

        this.Entorno.grid.headers = this.set_headers();

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'ciac_M';
      },

  
      // Recoge eventos de busqueda
      event_search(busqueda = "", header) {
        console.log("*** event_search. ciac_F: busqueda:" + busqueda + ", header:" + header + " ***");
        this.buscarExec(busqueda, header);
      },
    }
  };
</script>
